// Core
import { useEffect, useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';

// Material Component
import Slider from '@material-ui/core/Slider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Utils
import { useTranslation } from 'react-i18next';
import { GqlContext } from '@/contexts/gql';

// Custom Components
import PageWrapper from '@/components/Layout/PageWrapper';
import Header from '@/components/Header';
import FilterChip from '@/components/FilterChip';
import Category from '@/components/FilterComponents/Category';
import SubCategory from '@/components/FilterComponents/SubCategory';
import Brand from '@/components/FilterComponents/Brand';
import ProductButton from '@/components/FilterComponents/ProductButton';
import { SearchIcon } from '@/assets/icons/shared';

import { GET_PRODUCT_FILTERS } from '@/graphql/products';

// Styles
import { arraysAreEqual } from '@/utils/helper';
import styles from './style.module.css';

function Filter({
  handleBackButtonClick,
  setFilteredProducts,
  filters,
  setFilters,
  handleClearAll,
  query,
  visible,
  isDesktop = false,
  priceRange,
  setPriceRange,
  isOutOfStock,
  setIsOutOfStock,
  warehouseId,
  products,
}) {
  const { t: translate } = useTranslation();
  const router = useRouter();
  const [filterCache, setFilterCache] = useState(filters);
  const [getSearchResults, { data, loading }] = useLazyQuery(GET_PRODUCT_FILTERS);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [maxPrice, setMaxPrice] = useState(0);
  const [filteringProducts, setFilteringProducts] = useState(false);
  const { country } = useContext(GqlContext);
  const [filterMax, setFilterMax] = useState(100000);
  const [filterMin, setFilterMin] = useState(0);
  const [minMaxSet, setMinMaxSet] = useState(false);
  const [oldQuery, setOldQuery] = useState(null);
  const [oldPriceRange, setOldPriceRange] = useState(null);
  const [queryRefreshDone, setQueryRefreshDone] = useState(false);

  // const [insideCategory, setInsideCategory] = useState(false);

  const handleFilters = (newFilters) => {
    // console.log('New filters are', newFilters);
    let newUrl = router.asPath.split('?')[0];

    if (newFilters?.Categories?.length) {
      newUrl += `?cats=${newFilters.Categories.join(',')}`;
    }

    if (newFilters?.SubCategories?.length) {
      if (newUrl.includes('?')) {
        newUrl += `&subCat=${newFilters.SubCategories.join(',')}`;
      } else {
        newUrl += `?subCat=${newFilters.SubCategories.join(',')}`;
      }
    }

    if (newFilters?.brands?.length) {
      if (newUrl.includes('?')) {
        newUrl += `&brand=${newFilters.brands.join(',')}`;
      } else {
        newUrl += `?brand=${newFilters.brands.join(',')}`;
      }
    }
    setFilters(newFilters);
    setFilterCache(newFilters);
    newUrl = encodeURI(newUrl);
    router.push(newUrl);
  };

  const handleFilterClick = (key, item) => {
    const copiedFilters = { ...filters };
    const index = filters[key].findIndex((el) => el === item);

    if (index === -1) copiedFilters[key] = [...filters[key], item];
    else copiedFilters[key] = copiedFilters[key].filter((el) => el !== item);

    handleFilters(copiedFilters);
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  useEffect(() => {
    if (maxPrice === 0 && maxPrice !== priceRange[1]) {
      setMaxPrice(priceRange[1]);
    }
  }, [maxPrice, priceRange]);

  const getResults = () => {
    if (loading) return;
    const categories = filters.Categories.filter((c) => !!c);
    getSearchResults({
      variables: {
        categories,
        subCategories: filters.SubCategories,
        brands: filters.brands,
      },
    });
  };

  useEffect(() => {
    if (loading) return;
    console.log('\n\n FILTERS changed\n\n');
    getResults();
    setMinMaxSet(false);
    // setFilterCache(filters);
  }, [filters]);

  useEffect(() => {
    const queryChanged = query !== oldQuery;
    const priceRangeChanged = !arraysAreEqual(priceRange, oldPriceRange);

    if (priceRangeChanged) {
      setOldPriceRange(priceRange);
    }

    if (queryChanged) {
      setOldQuery(query);
    }

    if (priceRangeChanged || queryChanged) {
      getResults();
    }

    getResults();
  }, [query, priceRange]);

  useEffect(() => {
    if (!data || initialLoadDone) return;

    // eslint-disable-next-line
    const categoriesFound = data?.getProductFiltersApp?.categories.map((it) => it.key.toLowerCase(),);

    const isInsideCategory = !!query && categoriesFound.includes(query.toLowerCase());

    if (isInsideCategory && !initialLoadDone) {
      setInitialLoadDone(true);
      const categoryToUse = data?.getProductFiltersApp?.categories?.[
        categoriesFound.indexOf(query.toLowerCase())
      ]?.key;

      if (!filters?.Categories?.includes(categoryToUse)) {
        const newFilters = {
          ...filters,
          Categories: [categoryToUse, ...filters.Categories],
        };

        const filtersEqual = !Object.keys(newFilters)
          .map((key) => arraysAreEqual(newFilters[key], filters[key]))
          .includes(false);
        if (!filtersEqual) {
          setFilters(newFilters);
          setFilterCache(newFilters);

          console.log('\n\n FILTERS were NOT equal\n\n', newFilters, filters);
        } else {
          console.log('\n\n FILTERS were equal\n\n');
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (queryRefreshDone) return;
    // Filters pushed from
    const { brand, cats, subCat } = router.query;

    const newFilters = {
      Categories: cats ? cats.split(',') : [],
      SubCategories: subCat ? subCat.split(',') : [],
      brands: brand ? brand.split(',') : [],
    };
    // Construct new filters

    // Only set filters if they do not match the current filters object.
    const filtersEqual = !Object.keys(newFilters)
      .map((key) => arraysAreEqual(newFilters[key], filters[key]))
      .includes(false);
    if (!filtersEqual) {
      setFilters(newFilters);
      setFilterCache(newFilters);
    }

    setQueryRefreshDone(true);

    // setFilters(newFilters);
    // setFilterCache(newFilters);
  }, [router.query]);

  useEffect(() => {
    // Get maximum and minimum values for filter price slider
    if (minMaxSet) return; // Only fire when other filters have changed
    let allPrices = [];

    products.forEach((prod) => {
      allPrices.push(
        ...[prod.pricing?.retailPrice, prod?.pricing?.wholesalePrice],
      );
      prod?.cityWisePricing.forEach((cit) => {
        allPrices.push(cit?.pricing?.retailPrice);
        allPrices.push(cit?.pricing?.marketPrice);
      });
      prod?.sellers?.forEach((sl) => {
        allPrices.push(sl?.pricing?.retailPrice);
        allPrices.push(sl?.pricing?.wholesalePrice);
      });
    });

    allPrices = allPrices.filter((i) => i !== undefined);

    const newMax = allPrices?.length ? Math.max(...allPrices) : 100000;
    const newMin = allPrices?.length ? Math.min(...allPrices) : 0;

    setPriceRange([newMin, newMax]);
    setMinMaxSet(true);
    setFilterMin(newMin);
    setFilterMax(newMax);
  }, [products]);

  return visible ? (
    <PageWrapper childrenOnly>
      {!isDesktop ? (
        <Header
          title={translate('Filter')}
          showBackButtonAction
          onClick={handleBackButtonClick}
          action={(
            <button
              type="button"
              onClick={handleClearAll}
              className={styles.clear}
            >
              {translate('Clear All')}
            </button>
          )}
        />
      ) : (
        <Header title={translate('Filters')} className={styles.desktopHeader} />
      )}
      {loading || filteringProducts ? <LinearProgress /> : null}
      <>
        <Category
          categories={data ? data.getProductFiltersApp.categories : []}
          filter={filterCache}
          setFilters={handleFilters}
          handleFilterClick={handleFilterClick}
          // handleFilterClick={!insideCategory ? handleFilterClick : () => {}}
        />

        <SubCategory
          subCategories={data ? data.getProductFiltersApp.subCategories : []}
          filter={filterCache}
          setFilters={(val) => {
            handleFilters(val);
          }}
          handleFilterClick={handleFilterClick}
        />
        <Brand
          data={data ? data.getProductFiltersApp.brands : []}
          filter={filterCache}
          setFilters={handleFilters}
          handleFilterClick={handleFilterClick}
        />
      </>
      <div className={styles.filterGroup}>
        <div className="row j-space-between">
          <h4 className={styles.filterTitle}>PRICE </h4>
          <h4 className={styles.filterTitle}>
            {` ${country?.currencySymbol} `}
            {priceRange[0]}
            -
            {` ${country?.currencySymbol} `}
            {priceRange[1]}
          </h4>
        </div>
        <div className={styles.filterList}>
          <Slider
            value={priceRange}
            onChange={handlePriceChange}
            className={styles.slider}
            max={filterMax}
            min={filterMin}
          />
        </div>
      </div>
      <div className={styles.filterGroup}>
        <div className="row j-space-between">
          <h4 className={styles.filterTitle}>Availability</h4>
          <SearchIcon />
        </div>
        <div
          aria-hidden="true"
          className={styles.filterList}
          onClick={() => setIsOutOfStock(!isOutOfStock)}
        >
          <FilterChip
            label={translate('Include Out of Stock')}
            isActive={isOutOfStock}
          />
        </div>
      </div>

      <ProductButton
        isOutOfStock={isOutOfStock}
        filters={filters}
        priceRange={priceRange}
        setFilteredProducts={setFilteredProducts}
        onClick={handleBackButtonClick}
        warehouseId={warehouseId}
        searchQuery={query}
        isDesktop={isDesktop}
        productsCount={products?.length || 0}
        setFilteringProducts={setFilteringProducts}
      />
    </PageWrapper>
  ) : null;
}

export default Filter;
