export const mapFiltersToType = (filters = {}) => {
  const filterArrays = Object.keys(filters).map((type) => (filters[type] || []).map((filter) => ({
    filter,
    type,
  })));
  return [].concat(...filterArrays);
};

export const getSortParameters = (value = 'default') => {
  switch (value) {
    case 'asc_price':
      return {
        sortOrder: 'asc',
        sortBy: 'retailprice',
      };

    case 'desc_price':
      return {
        sortOrder: 'desc',
        sortBy: 'retailprice',
      };

    case 'discount':
      return {
        sortBy: 'discount',
      };

    case 'relevance':
      return {
        sortBy: 'relevance',
        sortOrder: 'desc',
      };

    case 'popularity':
      return {
        sortOrder: 'desc',
        sortBy: 'popularity',
      };

    case 'newarrival':
      return {
        sortOrder: 'desc',
        sortBy: 'date_created',
      };

    default:
      return {
        sortOrder: 'asc',
        sortBy: 'variant',
      };
  }
};
