import { useState } from 'react';
// Custom Components
import FilterChip from '@/components/FilterChip';
import ShowMoreLess from '@/components/FilterComponents/ShowMoreLess';

// Styles
import styles from '../style.module.css';

function Category({
  categories, handleFilterClick, filter = [], setFilters,
}) {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleClickAll = () => {
    const temp = categories.map((el) => el.key);
    const copiedFilters = { ...filter };
    copiedFilters.Categories = isAllSelected ? [] : temp;
    setFilters(copiedFilters);

    setIsAllSelected(!isAllSelected);
  };

  const handleLabel = (txt) => {
    if (txt === 'airtime') {
      return 'vouchers';
    }
    return txt;
  };

  return (
    <div className={styles.filterGroup}>
      <div className="row j-space-between">
        <h4 className={styles.filterTitle}>CATEGORY</h4>
      </div>
      <div className={styles.filterList}>
        {categories.length > 1 && (
          <div
            aria-hidden="true"
            onClick={handleClickAll}
            style={{ marginBottom: '12px', marginRight: '12px' }}
          >
            <FilterChip label="ALL" isActive={isAllSelected} />
          </div>
        )}

        {categories.map(
          (el, index) => (index < 6 || showMore) && (
          <div
            aria-hidden="true"
            key={`el${index}`}
            onClick={() => handleFilterClick('Categories', el.key)}
            style={{ marginBottom: '12px', marginRight: '12px' }}
          >
            <FilterChip
              label={handleLabel(el.key.toLowerCase())}
              isActive={filter.Categories.find(
                (filter) => filter === el.key,
              )}
            />
          </div>
          ),
        )}
      </div>
      {categories.length > 1 && (
        <ShowMoreLess value={showMore} setValue={setShowMore} />
      )}
    </div>
  );
}

export default Category;
