import { useState } from 'react';
// Custom Components
import FilterChip from '@/components/FilterChip';
import ExpandableSearch from '@/components/FilterComponents/ExpandableSearch';
import ShowMoreLess from '@/components/FilterComponents/ShowMoreLess';

// Utils
import { useTranslation } from 'react-i18next';

// Styles
import styles from '../style.module.css';

function Brand({
  data, filter, setFilters, handleFilterClick,
}) {
  const { t: translate } = useTranslation();
  const [searchKey, setSearchKey] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleClickAll = () => {
    const temp = data.map((el) => el.key);
    const copiedFilters = { ...filter };
    copiedFilters.brands = isAllSelected ? [] : temp;
    setFilters(copiedFilters);

    setIsAllSelected(!isAllSelected);
  };

  const renderItems = (brands) => brands.map(
    (el, index) => el.key.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        && (showMore || index < 6) && (
          <div
            aria-hidden="true"
            key={`${el.key}-${index}`}
            onClick={() => handleFilterClick('brands', el.key)}
            style={{ marginBottom: '12px', marginRight: '12px' }}
          >
            <FilterChip
              label={el.key.toLowerCase()}
              isActive={filter.brands.find((f) => f === el.key)}
            />
          </div>
    ),
  );

  return (
    <div className={styles.filterGroup}>
      <ExpandableSearch
        heading={translate('BRAND')}
        setSearchKey={setSearchKey}
      />

      <div className={styles.filterList}>
        {data.length > 1 && (
          <div
            aria-hidden="true"
            onClick={() => handleClickAll()}
            style={{ marginBottom: '12px', marginRight: '12px' }}
          >
            <FilterChip label="ALL" isActive={isAllSelected} />
          </div>
        )}

        {renderItems(data)}
      </div>
      {data.length > 1 && (
        <ShowMoreLess value={showMore} setValue={setShowMore} />
      )}
    </div>
  );
}

export default Brand;
