// Core
import classnames from 'classnames';

// Material
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';

// Custom Components
import styles from './style.module.css';

export default function StyledBottomSheet({
  options = [],
  selected,
  getLabel,
  getValue,
  onChange,
  onClose,
}) {
  const containerClasses = classnames(
    styles.drawerHeader,
    'row j-space-between',
  );

  const getListItemClasses = (option) => classnames(styles.listItem, 'row', {
    [styles.active]: getValue(option) === selected,
  });

  const onOptionClick = (option) => () => {
    onChange(option);
    onClose();
  };

  return (
    <Drawer anchor="bottom" onClose={onClose} open>
      <div className={containerClasses}>
        <span className={styles.label}>SORT BY</span>
        <span role="presentation" className={styles.closeBtn} onClick={onClose}>
          &#x2715;
        </span>
      </div>
      {options.map((option) => (
        <ListItem
          role="presentation"
          key={getValue(option)}
          className={getListItemClasses(option)}
          onKeyDown={onClose}
          value={getValue(option)}
          onClick={onOptionClick(option)}
          style={{ cursor: 'pointer' }}
        >
          {getLabel(option)}
        </ListItem>
      ))}
    </Drawer>
  );
}
