import { useEffect, useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';

// Styles
import classnames from 'classnames';

// Utils
import { productFilter } from '@/utils/analytics';
import { AuthUserContext } from '@/contexts/authUser';
// import { useTranslation } from "react-i18next";

import { GET_PRODUCTS_LIST } from '@/graphql/products';
import StyledButton from '@/components/Button';
import styles from '../style.module.css';

function ProductButton({
  filters,
  priceRange,
  isOutOfStock,
  setFilteredProducts,
  warehouseId,
  searchQuery,
  isDesktop = false,
  onClick,
  setFilteringProducts,
  productsCount,
}) {
  // const { t: translate } = useTranslation();
  const [getSearchResults, { data, loading }] = useLazyQuery(GET_PRODUCTS_LIST);
  const buttonClasses = classnames('row j-center a-center', styles.linkButton);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const { authUser } = useContext(AuthUserContext);
  const handleClick = () => {
    if (data) {
      if (authUser?.customerId) {
        productFilter(
          filters.Categories.join(','),
          filters.SubCategories.join(','),
          filters.brands.join(','),
          priceRange.join(','),
          isOutOfStock,
          authUser.customerId,
        );
      }
      setFilteredProducts(data?.getProducts?.nodes || []);
      if (onClick) onClick();
    }
  };

  useEffect(() => {
    setFilteringProducts(loading);
  }, [loading]);

  useEffect(() => {
    if (!initialLoadDone) return;
    let variables = {
      filters: {
        category: filters.Categories,
        subCategory: filters.SubCategories,
        discounts: filters.discounts,
        brand: filters.brands,
        min_price: priceRange[0],
        max_price: priceRange[1],
        outOfStock: isOutOfStock,
      },
      size: 200,
    };
    if (warehouseId) {
      variables = { ...variables, warehouse: warehouseId };
    }
    if (
      filters.Categories.length === 0
      && filters.SubCategories.length === 0
      && filters.brands.length === 0
      && searchQuery
    ) {
      variables = { ...variables, searchQuery };
    }

    getSearchResults({
      variables: {
        ...variables,
        platform: 'ALERZOSHOP_PWA',
        outOfStock: isOutOfStock,
      },
    });
  }, [filters, priceRange, isOutOfStock, warehouseId]);

  useEffect(() => {
    if (data?.getProducts?.nodes) {
      if (isDesktop) {
        setFilteredProducts(data?.getProducts?.nodes || []);
      }
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      setInitialLoadDone(true);
    }, 2000);
  }, []);

  return !isDesktop ? (
    <div className={styles.filterGroup}>
      <div>
        <StyledButton
          className={buttonClasses}
          onClick={handleClick}
          disabled={!productsCount}
        >
          {loading
            ? 'Filtering...'
            : `Show
          ${productsCount}
          Results
          `}
        </StyledButton>
      </div>
    </div>
  ) : null;
}

export default ProductButton;
