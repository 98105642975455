// Custom Components
import { useState } from 'react';
import FilterChip from '@/components/FilterChip';
import ExpandableSearch from '@/components/FilterComponents/ExpandableSearch';
import ShowMoreLess from '@/components/FilterComponents/ShowMoreLess';

// Styles
import styles from '../style.module.css';

function SubCategory({
  subCategories, handleFilterClick, filter, setFilters,
}) {
  const [searchKey, setSearchKey] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleClickAll = () => {
    const temp = subCategories.map((el) => el.key);
    const copiedFilters = { ...filter };
    copiedFilters.SubCategories = isAllSelected ? [] : temp;
    setFilters(copiedFilters);

    setIsAllSelected(!isAllSelected);
  };

  const renderItems = (subCategory) => subCategory.map(
    (el, index) => el.key.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        && (showMore || index < 6) && (
          <div
            id={el.key}
            aria-hidden="true"
            key={`${el.key}-${index}`}
            onClick={() => handleFilterClick('SubCategories', el.key)}
            style={{ marginBottom: '12px', marginRight: '12px' }}
          >
            <FilterChip
              label={el.key.toLowerCase()}
              isActive={filter.SubCategories.find(
                (filter) => filter === el.key,
              )}
            />
          </div>
    ),
  );

  return (
    <div className={styles.filterGroup}>
      <ExpandableSearch
        heading="SUB-CATEGORY"
        placeholder="Search by SubCategory"
        setSearchKey={setSearchKey}
      />
      <div className={styles.filterList}>
        {subCategories.length > 1 && (
          <div
            aria-hidden="true"
            onClick={handleClickAll}
            style={{ marginBottom: '12px', marginRight: '12px' }}
          >
            <FilterChip label="ALL" isActive={isAllSelected} />
          </div>
        )}
        {renderItems(subCategories)}
      </div>
      {subCategories.length > 1 && (
        <ShowMoreLess value={showMore} setValue={setShowMore} />
      )}
    </div>
  );
}

export default SubCategory;
