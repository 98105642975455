/* eslint-disable no-nested-ternary */
// Core
import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import classnames from 'classnames';
import Link from 'next/link';
import { CartContext } from '@/contexts/cart';
// import { useRouter } from 'next/router';

// Material
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Custom Components
import PageWrapper from '@/components/Layout/PageWrapper';
import GridView from '@/components/Grid';
import CompactProduct from '@/components/CompactProduct';
import { FilterIcon } from '@/assets/icons/shared';
import StyledBottomSheet from '@/components/BottomSheet';
import HorizontalScroll from '@/components/HorizontalScroll';
import { SortIcon } from '@/assets/icons/category';
import BackButton from '@/components/BackButtonNew';
import FilterScreen from '@/components/FilterComponents';
import StyledSelect from '@/components/Select';
import Pagination from '@/components/Pagination';
import BundlePopup from '@/containers/BundlePopup';
// import WarehouseLoader from '@/components/FilterComponents/warehouseLoader';
import { AuthUserContext } from '@/contexts/authUser';

import { GET_PRODUCTS_LIST } from '@/graphql/products';

// Utilities
import { useTranslation } from 'react-i18next';
import { viewProductListing, productSort } from '@/utils/analytics';
import { capitalizeText } from '@/utils/helper';
import useUrlSync from '../../hooks/urlSync';
import { mapFiltersToType } from './utils';

// Styles
import styles from './style.module.css';

function ProductsList(props) {
  const isDesktop = useMediaQuery('(min-width: 769px)');
  // const router = useRouter();
  const {
    title,
    isVeedezHome,
    sort,
    loading,
    products = [],
    newArrival = false,
    updateProductQuantity,
    cartItems,
    getItemQuantityForBundle,
    onClose,
    justProducts,
    warehouseForOrder,
    showMiniNotification,
    hideDesktopLayout,
    query,
    source = '',
    variant = '',
  } = props;
  const { t: translate } = useTranslation();
  const { getSellerDetails, getFirstWithIDInCart } = useContext(CartContext);
  const { warehouseId } = useContext(AuthUserContext);
  const [filters, setFilters] = useState({
    Categories: [],
    SubCategories: [],
    brands: [],
    discounts: [],
  });
  const [firstLoadDone, setFirstLoadDone] = useState(false);
  const [sortValue, setSortValue] = useState(sort);
  const [showBundlePopup, setShowBundlePopup] = useState('');
  const [showDesktopPopup, setShowDesktopPopup] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showFilterScreen, setShowFilterScreen] = useState(false);
  const [isSortCardVisible, setSortCardVisibility] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const [warehouseId, setWarehouseId] = useState('');
  const [prodList, setProdList] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [getSearchResults, { data, loading: productLoading }] = useLazyQuery(GET_PRODUCTS_LIST);
  const headerClasses = classnames(
    styles.header,
    'row j-space-between a-center',
  );
  const productsGridClasses = classnames(
    justProducts ? styles.productsGridAlt : styles.productsGrid,
  );
  const buttonClasses = classnames('row j-center a-center', styles.button);
  const largeScreenClasses = classnames(
    styles.outerContainer,
    styles.largeScreenOnly,
  );
  const filtersList = mapFiltersToType(filters);
  useUrlSync({ sort: sortValue });

  const handleProductQuantity = (product, quantity) => {
    updateProductQuantity(product, quantity);
  };

  const handleFilterScreenToggle = () => {
    setShowFilterScreen(!showFilterScreen);
  };

  const handleShowBundle = (bundleReceived, isDesktop) => {
    setShowBundlePopup(bundleReceived._id);
    setShowDesktopPopup(isDesktop);
  };

  const handleSetFilteredProducts = (products) => {
    setFilteredProducts(products);
  };

  useEffect(() => {
    if (data?.getProducts?.nodes) {
      setFilteredProducts(data?.getProducts?.nodes || []);
    }
  }, [data]);

  const removeFilter = ({ type, filter }) => {
    const newFilters = {
      ...filters,
      [type]: filters[type].filter((value) => value !== filter),
    };

    setFilters(newFilters);

    let variables = {
      filters: {
        category: newFilters.Categories,
        subCategory: newFilters.SubCategories,
        discounts: newFilters.discounts,
        brand: newFilters.brands,
        min_price: priceRange[0],
        max_price: priceRange[1],
        outOfStock: isOutOfStock,
      },
      size: 200,
    };
    if (warehouseId) {
      variables = { ...variables, warehouse: warehouseId };
    }
    if (
      filters.Categories.length === 0
      && filters.SubCategories.length === 0
      && filters.brands.length === 0
      && query
    ) {
      variables = { ...variables, searchQuery: query };
    }

    getSearchResults({
      variables: {
        ...variables,
        platform: 'ALERZOSHOP_PWA',
        outOfStock: isOutOfStock,
      },
    });
  };
  const getKey = (key) => (option) => option[key];
  const removeActiveFilter = (filter) => () => removeFilter(filter);
  const setSortState = (option) => setSortValue(option.value);
  const setSortCardState = (value) => () => setSortCardVisibility(value);
  const handleTitle = () => {
    let result = '';
    if (query) {
      result = query === 'Airtime' ? 'Vouchers' : query;
    }
    if (title) {
      result = title === 'Airtime' ? 'Vouchers' : title;
    }
    return result;
  };

  const handleClearAll = () => {
    setFilters({
      Categories: [],
      SubCategories: [],
      brands: [],
      discounts: [],
    });
  };

  useEffect(() => {
    console.log(query, 'is query & customerId is', props?.customerId);
    if (
      [
        'Best Selling',
        'Trending Products',
        'Recommended',
        'You May Also Like',
        'Explore More',
        'Similar Items',
      ].includes(query)
    ) {
      if (props?.customerId?.length > 0) {
        viewProductListing(
          query.toLowerCase(),
          warehouseForOrder,
          props.customerId,
        );
      }
    }
  }, []);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  useEffect(() => {
    if (firstLoadDone) return;
    setFirstLoadDone(true);
    setCurrentPage(1);
  }, [filteredProducts]);

  useEffect(() => {
    handleClearAll();
  }, [query]);

  useEffect(() => {
    if (!firstLoadDone) return;
    const newProds = [...filteredProducts].slice(
      (currentPage - 1) * 24,
      currentPage * 24,
    );
    setProdList(newProds);
  }, [currentPage, filteredProducts]);

  const productSortAnalytics = (option) => {
    if (props?.customerId?.length > 0) {
      if (option && option.label) {
        const sortOptions = option.label.split('-');
        const sortOrder = sortOptions.length === 2 ? sortOptions[1] : 'N/A';
        productSort(sortOptions[0], sortOrder, props.customerId);
      }
    }
  };

  return (
    <>
      {/* <WarehouseLoader authUser={authUser} setWarehouseId={setWarehouseId} /> */}
      {showBundlePopup && (
        <BundlePopup
          setOpen={setShowBundlePopup}
          updateProductQuantity={updateProductQuantity}
          getItemQuantityForBundle={getItemQuantityForBundle}
          bundleId={showBundlePopup}
          cartItems={cartItems}
          showMiniNotification={showMiniNotification}
          showDesktopPopup={showDesktopPopup}
        />
      )}
      <div className={largeScreenClasses}>
        {isDesktop && (
          <PageWrapper childrenOnly={hideDesktopLayout}>
            {isVeedezHome ? (
              justProducts ? (
                <div className={headerClasses}>
                  <div
                    style={{ padding: '32px', margin: '-16px 0px -32px 0' }}
                    className="row a-center flex-1 overflow-hidden"
                  >
                    <BackButton className={styles.backBtn} onClick={onClose} />
                    {source ? (
                      <h3>
                        {`Home / ${source} / ${capitalizeText(variant)} / `}
                        <b>{handleTitle()}</b>
                      </h3>
                    ) : variant ? (
                      <h3>
                        {`Home / ${capitalizeText(variant)} / `}
                        <b>{handleTitle()}</b>
                      </h3>
                    ) : (
                      <h3>
                        {'Home / '}
                        <b>{handleTitle()}</b>
                      </h3>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.desktopHeader}>
                  <h3>
                    Home /
                    <b>
                      {filters?.Categories?.length
                        ? filters?.Categories?.[0]
                        : 'All Categories'}
                    </b>
                  </h3>
                  <div className={styles.desktopListingInfo}>
                    <div className={styles.desktopFiltersContainer}>
                      <div className={styles.desktopProductsCount}>
                        Results:
                        {' '}
                        <b>{filteredProducts?.length || 0}</b>
                      </div>
                      <div className={styles.desktopFilters}>
                        {!!filtersList.length && (
                          <>
                            {filtersList.map((item, index) => {
                              const { filter, type } = item;
                              return (
                                <Chip
                                  key={`${filter}_${index}`}
                                  label={filter}
                                  className={styles.chip}
                                  onDelete={removeActiveFilter({
                                    filter,
                                    type,
                                  })}
                                />
                              );
                            })}
                            <span
                              role="presentation"
                              className={styles.desktopClearAll}
                              onClick={handleClearAll}
                            >
                              {translate('Clear All')}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={styles.desktopSortOptions}>
                      Sort By:
                      <StyledSelect
                        className={styles.desktopSortByInput}
                        value={sortValue}
                        options={[
                          newArrival
                            ? {
                              label: translate('New Arrival'),
                              value: 'newarrival',
                            }
                            : {
                              label: translate('Relevance'),
                              value: 'relevance',
                            },
                          {
                            label: translate('Popularity'),
                            value: 'popularity',
                          },
                          {
                            label: translate('Price - low to high'),
                            value: 'asc_price',
                          },
                          {
                            label: translate('Price - high to low'),
                            value: 'desc_price',
                          },
                        ]}
                        getLabel={getKey('label')}
                        getValue={getKey('value')}
                        onChange={({ value }) => setSortValue(value)}
                      />
                    </div>
                  </div>
                </div>
              )
            ) : null}
            <div className={styles.desktopProductListing}>
              {justProducts ? null : (
                <div className={styles.desktopFilterPanel}>
                  <FilterScreen
                    setFilteredProducts={handleSetFilteredProducts}
                    handleBackButtonClick={handleFilterScreenToggle}
                    filters={filters}
                    setFilters={setFilters}
                    query={query}
                    visible
                    isDesktop
                    priceRange={priceRange}
                    setPriceRange={setPriceRange}
                    isOutOfStock={isOutOfStock}
                    setIsOutOfStock={setIsOutOfStock}
                    warehouseId={warehouseId}
                    products={filteredProducts}
                  />
                </div>
              )}
              <div className={styles.desktopProducts}>
                {prodList?.length > 0 ? (
                  <>
                    <GridView className={productsGridClasses} gap="1rem">
                      {prodList.map((product) => (
                        <CompactProduct
                          key={product._id}
                          product={product}
                          className="card-size-desktop"
                          cartQuantity={
                            getFirstWithIDInCart(product._id)?.cartQuantity || 0
                          }
                          sellerDetails={getSellerDetails(
                            product._id,
                            product.sellerId,
                          )}
                          onQuantityChange={handleProductQuantity}
                          showBundle={(bundleReceived) => {
                            handleShowBundle(bundleReceived, true);
                          }}
                          cartItems={cartItems}
                          getItemQuantityForBundle={getItemQuantityForBundle}
                          showMiniNotification={showMiniNotification}
                        />
                      ))}
                    </GridView>

                    <Pagination
                      data-test="component-table-pagination"
                      page={currentPage}
                      totalItems={filteredProducts.length}
                      size={24}
                      onChange={setCurrentPage}
                    />
                  </>
                ) : (
                  <div className="col">
                    <img
                      src="/images/noProduct.png"
                      alt="No Result"
                      className={styles.image}
                    />
                    <p className={styles.message}>
                      {translate('No products found')}
                    </p>
                    <div className={buttonClasses}>
                      <Link href="/category">
                        {translate('Browse Categories')}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </PageWrapper>
        )}
      </div>

      <div className={styles.smallScreenOnly}>
        {!isDesktop && (
          <>
            <FilterScreen
              setFilteredProducts={handleSetFilteredProducts}
              handleBackButtonClick={handleFilterScreenToggle}
              filters={filters}
              setFilters={setFilters}
              handleClearAll={handleClearAll}
              query={query}
              visible={showFilterScreen}
              priceRange={priceRange}
              setPriceRange={setPriceRange}
              isOutOfStock={isOutOfStock}
              setIsOutOfStock={setIsOutOfStock}
              warehouseId={warehouseId}
              products={filteredProducts}
            />
            {!showFilterScreen && (
              <PageWrapper>
                <div className={headerClasses}>
                  <div
                    style={{ padding: '16px' }}
                    className="row a-center flex-1 overflow-hidden"
                  >
                    <BackButton className={styles.backBtn} onClick={onClose} />
                    <span className={styles.title}>{handleTitle()}</span>
                  </div>
                  {justProducts
                    ? null
                    : !!products.length && (
                    <div className="row a-center">
                      <Button
                        className={styles.sortBtn}
                        onClick={setSortCardState(true)}
                      >
                        <SortIcon />
                        <span className={styles.label}>
                          {translate('Sort')}
                        </span>
                      </Button>
                      <Button
                        className={styles.filterBtn}
                        onClick={handleFilterScreenToggle}
                      >
                        <FilterIcon />
                        <span className={styles.label}>
                          {translate('Filter')}
                        </span>
                      </Button>
                    </div>
                    )}
                </div>
                {!!filtersList.length && (
                  <HorizontalScroll className={styles.chipsContainer}>
                    {filtersList.map(({ filter, type }, index) => (
                      <Chip
                        key={`${filter}_${index}`}
                        label={filter}
                        className={styles.chip}
                        onDelete={removeActiveFilter({ filter, type })}
                      />
                    ))}
                  </HorizontalScroll>
                )}
                {(loading || productLoading) && <LinearProgress />}
                {products?.length > 0 ? (
                  <div className={styles.gridSectionHeader}>
                    <GridView gap="1rem">
                      {filteredProducts.map((product) => (
                        <CompactProduct
                          key={product._id}
                          className="card-size"
                          product={product}
                          cartQuantity={
                            getFirstWithIDInCart(product._id)?.cartQuantity || 0
                          }
                          onQuantityChange={handleProductQuantity}
                          showBundle={(bundleReceived) => {
                            handleShowBundle(bundleReceived, false);
                          }}
                          grid
                          cartItems={cartItems}
                          getItemQuantityForBundle={getItemQuantityForBundle}
                          showMiniNotification={showMiniNotification}
                        />
                      ))}
                    </GridView>
                  </div>
                ) : (
                  <div className="col">
                    <img
                      src="/images/noProduct.png"
                      alt="No Result"
                      className={styles.image}
                    />
                    <p className={styles.message}>No products found</p>
                    <div className={buttonClasses}>
                      <Link href="/category">
                        {translate('Browse Categories')}
                      </Link>
                    </div>
                  </div>
                )}

                {isSortCardVisible && (
                  <StyledBottomSheet
                    options={[
                      newArrival
                        ? { label: 'New Arrival', value: 'newarrival' }
                        : { label: 'Relevance', value: 'relevance' },
                      { label: 'Popularity', value: 'popularity' },
                      { label: 'Price - low to high', value: 'asc_price' },
                      { label: 'Price - high to low', value: 'desc_price' },
                    ]}
                    selected={sortValue}
                    getLabel={getKey('label')}
                    getValue={getKey('value')}
                    onClose={setSortCardState(false)}
                    onChange={(option) => {
                      setSortState(option);
                      productSortAnalytics(option);
                    }}
                  />
                )}
              </PageWrapper>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ProductsList;
